import axios from 'axios';
import http from './http';
import docCookies from '@/utils/cookie';
import localStorageAPI from '@/utils/localstorage';
import { md5 } from '@/utils/md5';

const cookieRefreshTokenKey = '.auth.refreshtoken.APM0010480';
const cookieTokenKey = '.auth.token.APM0010480';

export function removeRefreshToken() {
  //return docCookies.removeItem(cookieRefreshTokenKey);
  return localStorageAPI.removeItem(cookieRefreshTokenKey);
}
export function getRefreshToken() {
  //return docCookies.getItem(cookieRefreshTokenKey);
  return localStorageAPI.getItem(cookieRefreshTokenKey);
}
export function setRefreshToken(token) {
  //docCookies.setItem(cookieRefreshTokenKey, token);
  localStorageAPI.setItem(cookieRefreshTokenKey, token);
}

export function removeAuthToken() {
  //return docCookies.removeItem(cookieTokenKey);
  return localStorageAPI.removeItem(cookieTokenKey);
}
export function getAuthToken() {
  //return docCookies.getItem(cookieTokenKey);
  return localStorageAPI.getItem(cookieTokenKey);
}
export function setAuthToken(token) {
  //docCookies.setItem(cookieTokenKey, token);
  localStorageAPI.setItem(cookieTokenKey, token);
}

export function authorizeCode(code, redirect_uri) {
  const data = {
    grant_type: 'authorization_code',
    code,
    redirect_uri,
  };
  return getToken(data);
}
export function refreshToken(refresh_token) {
  const data = {
    grant_type: 'refresh_token',
    refresh_token,
  };
  return getToken(data);
}

function getToken(data) {
  var auth_token = getAuthToken();
  if (auth_token && data.grant_type === 'refresh_token') {
    data.access_token = auth_token.substring(7);
  }

  data.client_id = 'web';
  data.timestamp = parseInt(new Date().getTime() / 1000);
  data.client_secret = getSecret(data.timestamp);
  return axios
    .post(window.ENV_CFG.token, data)
    .then(res => {
      if (res.data?.access_token) {
        http.setAuthorization(res.data);
        setAuthToken(res.data.token_type + ' ' + res.data.access_token);
        setRefreshToken(res.data.refresh_token);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    })
    .catch(e => {
      return Promise.reject(e);
    });
}
function getSecret(timestamp) {
  // const hash = md5('0kVOF1PeA9mg6WZf' + timestamp);
  const hash = md5('OXkUqTCGavyxdDrPHLdbAttVPRWpxWHT' + timestamp);
  return hash.slice(8, 16 + (timestamp % 16));
}
